<template>
	<div>
		<div class="top-cover">
			<img src="../../assets/common/cover_3.png" alt>
		</div>
		<div v-if="result" class="operation csc">
			<t-title :title="result.title" eng="OPERATION PROCESS" />
			<div class="section" v-html="result.content"></div>
		</div>
	</div>
</template>

<script>
	import TTitle from 'components/title'
	export default {
		components: {
			TTitle,
		},
		data() {
			return {
				result: null
			}
		},
		mounted() {
			let {
				id
			} = this.$route.query
			this.articleInfo(id)
		},
		methods: {
			async articleInfo(id) {
				let {result}=await this.$api.articleInfo(id)
				this.result=result
			}
		}
	}
</script>

<style lang="scss" scoped>
	.top-cover {
		width: 100%;
		height: 450px;
		background: #ededed;

		img {
			max-width: 1920px;
			min-width: 1200px;
			width: 100%;
			height: 100%;
			display: block;
			margin: 0 auto;
		}
	}

	.operation {
		width: 100%;
		height: 950px;
		background: #fff;
		padding-top: 80px;

		.section {
			width: 1000px;
			margin-top: 170px;
		}
	}
</style>
